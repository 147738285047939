<template>
<div class="blog-area" style="padding-top: 2vh !important;">
    <div class="container">
        <div class="row">
            <vueper-slides 
    autoplay
    rtl
    class="no-shadow"
    :visible-slides="4"
    slide-multiple
    :gap="3"
    :slide-ratio="1 / 5"
    :dragging-distance="200"
    :breakpoints="{ 800: { visibleSlides: 1, slideMultiple: 1 } }">
    <vueper-slide class="card__cover" v-for="publish in slides.slice(0,8)" :key="publish.id" :image="publish.screenShot" @click="getFeed(publish)" />
  </vueper-slides>
        </div>
    </div>
</div>
    <!-- blog area start -->
    <div class="blog-area pd-top-100 pd-bottom-100" style="padding-top: 4vh;">
        <div class="container">
            <div class="row">
                <div class="col-lg-8">
                    <div class="blog-details-page-content pb-0">
                        <div class="single-blog-inner"  v-for="publish in gameDetail" :key="publish.id">
                            <!-- <div class="thumb">
                                <img v-lazy="publish.imageSlide" alt="img">
                            </div> -->
                            <div class="details">
                                <ul class="blog-meta">
                                    <li><i class="far fa-calendar-check"></i>{{ publish.insert_date.substring(0,10) }}</li>
                                </ul>
                                <h3 class="title arabic-kufi">{{ publish.name }}</h3>
                                <p class="arabic-kufi">{{ publish.description_ar }}</p>
                                <br>
                                   <div class="btn-wrap">
                            <button class="btn btn-base" style="width: 100%;"  @click="Login()">  
                                <div v-if="loading" class="spinner-border spinner-border-sm"></div>
                                <!-- <span v-if="loading" class="px-1">تسجيل الدخول</span> -->
                                <span v-else>العب الان</span>
                            </button>
                        </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-12">
                    <div class="td-sidebar">
                        <!-- <div class="widget widget_search">    
                            <h4 class="widget-title">Search</h4>                                 
                            <form class="search-form">
                                <div class="form-group">
                                    <input type="text" placeholder="Search">
                                </div>
                                <button class="submit-btn" type="submit"><i class="fa fa-search"></i></button>
                            </form>
                        </div>  -->
                        <div class="widget widget_catagory">
                            <h4 class="widget-title arabic-kufi">التصنيفات</h4>                                 
                            <ul class="catagory-items arabic-kufi">
                                <li><a href="">أكشن </a></li>
                                <li><a href="">مغامرات</a></li>
                                <li><a href="">الاستراتيجية</a></li>
                                <li><a href="">العاب اندرويد </a></li>
                            </ul>
                        </div>                  
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/Api/http-common';
import { ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
// import { useStorage } from "vue3-storage";
export default {
    props: {
       id: String,
    },
    components: { 
       VueperSlides, 
       VueperSlide 
       },
    async setup(props) {
        const router = useRouter();
        const route = useRoute();
        const gameDetail = ref([]);
        const slides = ref([]);
        try {
          await HTTP.get(`readPost.php?id=${props.id}`).then((res) => {
               gameDetail.value = res.data.readPost;
               });
        } catch (err) {
            console.log(err);
        }
        try {
            await HTTP.get(`screenShot.php?id=${props.id}`).then((res) => {
               slides.value = res.data.getScreen;
               });
        } catch (err) {
            console.log(err);
        }
        const Login = () => {
            router.push({ name: "Play", params: { id: props.id } });
        }
   
    return {
        gameDetail,
        slides,
        Login
    }
    },
}
</script>
<style >
.vueperslide {
 white-space:normal;
 background-size:cover;
 flex-shrink:0;
 display:block;
 width:100%;
 position:relative
}
.vueperslide--clone-1 {
 position:absolute;
 top:0;
 bottom:0;
 right:100%
}
.vueperslides--rtl .vueperslide--clone-1 {
 right:auto;
 left:100%
}
.vueperslide[href] {
 -webkit-user-drag:none
}
.vueperslide__image {
 position:absolute;
 top:0;
 left:0;
 right:0;
 bottom:0;
 background-size:cover
}
.vueperslide__loader {
 position:absolute;
 top:0;
 bottom:0;
 left:0;
 right:0;
 display:flex;
 flex-direction:column;
 align-items:center;
 justify-content:center
}
.vueperslide__content-wrapper:not(.vueperslide__content-wrapper--outside-top):not(.vueperslide__content-wrapper--outside-bottom) {
 height:100%;
 margin:auto
}
.vueperslides--fade .vueperslide {
 position:absolute;
 top:0;
 left:0;
 right:0;
 bottom:0;
 opacity:0;
 transition:ease-in-out opacity;
 transition-duration:inherit
}
.vueperslides--fade .vueperslide--active,
.vueperslides--fade .vueperslide--visible {
 z-index:1;
 opacity:1
}
.vueperslides--slide-image-inside .vueperslide {
 overflow:hidden
}
.vueperslides--3d .vueperslide {
 position:absolute;
 z-index:-1;
 height:100%
}
.vueperslides--3d .vueperslide--previous-slide,
.vueperslides--3d .vueperslide--active,
.vueperslides--3d .vueperslide--next-slide {
 z-index:0
}
.vueperslides--3d .vueperslide--active {
 z-index:1
}
.vueperslides--3d .vueperslide[face=front] {
 transform:rotateY(90deg) translate(-50%) rotateY(-90deg)
}
.vueperslides--3d .vueperslide[face=right] {
 transform:rotateY(90deg) translate(50%);
 transform-origin:100% 0
}
.vueperslides--3d .vueperslide[face=back] {
 transform:rotateY(270deg) translate(-50%) rotateY(-90deg)
}
.vueperslides--3d .vueperslide[face=left] {
 transform:rotateY(270deg) translate(-50%);
 transform-origin:0 0
}
.vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:before,
.vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:after {
 content:"";
 position:absolute;
 bottom:100%;
 left:-1em;
 right:-1em;
 height:2em;
 box-shadow:0 0 20px #00000040;
 z-index:2
}
.vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:after {
 top:100%;
 bottom:auto
}
.vueperslides__arrows {
 color:#fff
}
.vueperslides__arrows--outside {
 color:currentColor
}
.vueperslides__arrow {
 top:50%;
 background-color:transparent;
 border:none;
 opacity:.7
}
.vueperslides__arrow--prev,
.vueperslides--rtl .vueperslides__arrow--next {
 right:auto;
 left:.5em
}
.vueperslides__arrow--next,
.vueperslides--rtl .vueperslides__arrow--prev {
 left:auto;
 right:.5em
}
.vueperslides__arrow:hover {
 opacity:1
}
.vueperslides__arrows--outside .vueperslides__arrow--prev,
.vueperslides--rtl .vueperslides__arrows--outside .vueperslides__arrow--next {
 right:auto;
 left:-3.5em
}
.vueperslides__arrows--outside .vueperslides__arrow--next,
.vueperslides--rtl .vueperslides__arrows--outside .vueperslides__arrow--prev {
 left:auto;
 right:-3.5em
}
.vueperslides__paused {
 top:.7em;
 right:.7em;
 opacity:0;
 text-shadow:0 0 3px rgba(0,0,0,.4);
 z-index:1
}
.vueperslides:hover .vueperslides__paused {
 opacity:1
}
.vueperslides__bullets:not(.vueperslides__bullets--outside) {
 color:#481582
}
.vueperslides__bullet {
 margin:1.5em .6em;
 padding:0;
 border:none;
 background:none
}
.vueperslides__bullet .default {
 width:12px;
 height:12px;
 border-radius:12px;
 border:1px solid currentColor;
 background-color:transparent;
 box-shadow:0 0 1px #00000080,0 0 3px #0000004d;
 transition:.4s ease-in-out;
 box-sizing:border-box
}
.vueperslides__bullet .default span {
 display:none
}
.vueperslides__bullet--active .default {
 border-width:6px
}
.vueperslide,
.vueperslide__image {
 background-position:center
}
.vueperslide__video {
 outline:none
}
.vueperslide--no-pointer-events:before {
 content:"";
 position:absolute;
 top:0;
 bottom:0;
 left:0;
 right:0
}
.vueperslide__content-wrapper:not(.vueperslide__content-wrapper--outside-top):not(.vueperslide__content-wrapper--outside-bottom) {
 display: flex;
flex: 1 1 auto;
flex-direction: column;
align-items: center;
justify-content: center;
text-align: center;
/* background: #00000096; */
height: 25%;
bottom: 0px;
position: absolute;
width: 100%;
/* border: 2px solid #5375AC; */
font-size: 16px;
color: #fff;
z-index: 999;
border-radius: 10px;
flex-direction: column;
}
.vueperslide__content-wrapper.parallax-fixed-content,
.vueperslide--has-image-inside .vueperslide__content-wrapper,
.vueperslide--has-video .vueperslide__content-wrapper {
 position:absolute;
 z-index:2;
 top:0;
 bottom:0;
 left:0;
 right:0;
 display:flex;
 flex-direction:column;
 justify-content:center;
 align-items:center;
 pointer-events:none
}
.vueperslides {
 position:relative;
 min-height: 40vh;
}
.vueperslides--fixed-height .vueperslides__inner,
.vueperslides--fixed-height .vueperslides__parallax-wrapper,
.vueperslides--fixed-height .vueperslide {
 height:inherit
}
.vueperslides--fixed-height .vueperslides__parallax-wrapper {
 padding-bottom:0!important
}
.vueperslides--fixed-height.vueperslides--bullets-outside {
 margin-bottom:4em
}
.vueperslides__inner {
 position:relative;
 -webkit-user-select:none;
 -moz-user-select:none;
 user-select:none;
 min-height: 25vh;
}
.vueperslides__parallax-wrapper {
 position:relative;
 overflow:hidden;
 min-height: 30vh;
}
.vueperslides--3d .vueperslides__parallax-wrapper {
 overflow:visible
}
.vueperslides__track {
 position:absolute;
 top:0;
 height:100%;
 left:0;
 right:0;
 overflow:hidden;
 z-index:1;
 border-radius: 12px
}
.vueperslides--parallax .vueperslides__track {
 height:200%;
 transform:translateY(0)
}
.vueperslides--touchable .vueperslides__track {
 cursor:ew-resize;
 cursor:-webkit-grab;
 cursor:grab
}
.vueperslides--touchable .vueperslides__track--mousedown,
.vueperslides--touchable .vueperslides__track--dragging {
 cursor:-webkit-grabbing;
 cursor:grabbing
}
.vueperslides--3d .vueperslides__track {
 overflow:visible;
 perspective:100em
}
.vueperslides__track-inner {
 white-space:nowrap;
 transition:.5s ease-in-out transform;
 height:100%;
 display:flex
}
.vueperslides--no-animation .vueperslides__track-inner {
 transition-duration:0s!important
}
.vueperslides--fade .vueperslides__track-inner {
 white-space:normal;
 transition:none
}
.vueperslides--3d .vueperslides__track-inner {
 transform-style:preserve-3d
}
.vueperslides__track--mousedown .vueperslides__track-inner {
 transition:.25s ease-in-out transform!important
}
.vueperslides__track--dragging .vueperslides__track-inner {
 transition:none
}
.vueperslides__arrow {
 position:absolute;
 font-size:inherit;
 color:inherit;
 text-align:center;
 transform:translateY(-50%);
 transition:.3s ease-in-out;
 cursor:pointer;
 -webkit-user-select:none;
 -moz-user-select:none;
 user-select:none;
 outline:none;
 z-index:2;
 line-height:1
}
.vueperslides__arrow svg {
 vertical-align:middle;
 stroke:currentColor;
 fill:none;
 width:3.5em;
 padding:1em;
 stroke-width:1;
 transition:.3s ease-in-out;
 box-sizing:border-box;
  display: none
}
.vueperslides__arrow svg:hover {
 stroke-width:1.3
}
.vueperslides__paused {
 position:absolute;
 transition:.3s ease-in-out
}
.vueperslides__bullets {
 display:flex;
 justify-content:center;
 position:absolute;
 bottom:0;
 left:0;
 right:0
}
.vueperslides__bullets--outside {
 position:relative
}
.vueperslides__bullets button,
.vueperslides__bullet {
 cursor:pointer;
 -webkit-user-select:none;
 -moz-user-select:none;
 user-select:none;
 outline:none;
 z-index:2;
 display:flex;
 justify-content:center;
 align-items:center;
 color:inherit
}
.vueperslides__bullets button::-moz-focus-inner,
.vueperslides__bullet::-moz-focus-inner {
 border:0
}
.vueperslides__fractions {
 position:absolute;
 top:.8em;
 left:.5em;
 z-index:2;
 padding:.2em 1em;
 border:1px solid rgba(255,255,255,.5);
 border-radius:2em;
 background:rgba(255,255,255,.2);
 color:#fff
}
.vueperslides__progress {
 position:absolute;
 top:0;
 left:0;
 right:0;
 z-index:2;
 height:6px;
 color:#481582
}
.vueperslides__progress>* {
 position:absolute;
 top:0;
 bottom:0;
 left:0;
 background:currentColor;
 transition:.3s ease-in-out
}
@media(max-width:768px){
.vueperslides__bullets {
        top: 100%;
}
}

.vueperslides__bullets button,
.vueperslides__bullet {
 cursor:pointer;
 -webkit-user-select:none;
 -moz-user-select:none;
 -ms-user-select:none;
     user-select:none;
 outline:none;
 z-index:2;
 display:flex;
 justify-content:center;
 align-items:center;
 color:#481582;
}

.vueperslides {  top: 60px; }

</style>
<style scoped>
.pointer {
  cursor: pointer;
}
.widget ul {
  text-align: right;
}






</style>